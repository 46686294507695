<template>
  <div class="debit-card flex flex-col">
    <div class="card__header flex justify-between card-header px-5">
      <div class="flex flex-col">
        <h4 class="m-0 card__title">{{ bank.accountName }}</h4>
        <p>{{ bank.accountId }}</p>
      </div>
      <edit-2-icon
        class="edit-card"
        style="width: 20px"
        @click="handleEdit"
      ></edit-2-icon>
    </div>
    <div class="card__body px-5">
      <div class="card__content">
        <span>Account Name *</span>
        <span>{{ this.bank.displayName }}</span>
      </div>
      <div class="flex justify-between w-full my-5">
        <div class="card__content">
          <span>BSB</span>
          <span>{{ this.bank.bsb }}</span>
        </div>
        <div class="card__content">
          <span>Account Number</span>
          <span>{{ this.bank.accountNumber }}</span>
        </div>
      </div>
      <vs-button
        v-round
        class="w-full my-5"
        v-if="paymentOptions.includes('pay-now') && !bank.tillConnector"
        @click="addEditTill"
        >Configure till connector</vs-button
      >
      <vs-button
        v-round
        class="w-full only-border-btn my-5"
        v-if="paymentOptions.includes('pay-now') && bank.tillConnector"
        @click="addEditTill"
        >Edit till connector</vs-button
      >
    </div>
  </div>
</template>

<script>
import { Edit2Icon } from "vue-feather-icons";
export default {
  name: "DebitCardComponent",
  components: {
    Edit2Icon,
  },

  props: {
    bank: {
      type: Object,
      required: true,
    },
    paymentOptions: {
      type: Array,
      required: true,
    },
  },

  methods: {
    handleEdit() {
      this.$emit("edit");
    },
    addEditTill() {
      this.$emit("tillChange");
    },
  },
};
</script>