<template>
  <div class="debit-card flex flex-col">
    <div class="card__header flex justify-between card-header px-5">
      <div class="flex flex-col">
        <h4 class="m-0 card__title">{{ bank.displayName }}</h4>
        <p>{{ bank.bankAccountId }}</p>
      </div>
      <edit-2-icon v-if="hasPermission" class="edit-card w-5" @click="handleEdit"></edit-2-icon>
    </div>
    <div class="card__body px-5">
      <div class="card__content">
        <span v-if="bank.isUsedForBilling === true && bank.isUsedForSettlement === false">Account is used for billing.</span>
        <span v-if="bank.isUsedForBilling === false && bank.isUsedForSettlement === true">Account is used for settlement.</span>
        <span v-if="bank.isUsedForBilling === true && bank.isUsedForSettlement === true">Account is used for settlement & billing.</span>
      </div>
      <div class="card__content mt-4">
        <span>Account Name *</span>
        <span>{{ bank.accountName }}</span>
      </div>
      <div class="flex justify-between w-full mt-5">
        <div class="card__content">
          <span>BSB</span>
          <span>{{ maskBsb(bank.bsb) }}</span>
        </div>
        <div class="card__content">
          <span>Account Number</span>
          <span>{{ bank.accountNumber }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Edit2Icon } from "vue-feather-icons";

export default {
  name: "DebitCardComponent",
  components: {
    Edit2Icon,
  },

  props: {
    bank: {
      type: Object,
      required: true,
    },
    hasPermission: {
      type: Boolean,
      required: true,
    },
    paymentOptions: {
      type: Object,
      required: true,
    },
  },

  methods: {
    handleEdit() {
      this.$emit("edit");
    },

    maskBsb(string){
      let arr= string.replace(" ", "").split("");
      arr.splice(3, 0, "-");
      string = arr.join("");
      return string;
    }
  },
};
</script>

